import { defineStore } from 'pinia'

export const useIndexStore = defineStore('IndexStore', {
	state: () => ({
		entities: [],
		count: 0,
		loading: true,
		dim: false,
	}),
	actions: {
		async storeEntities({ entities, count }) {
			this.entities = entities
			this.count = count
			this.loading = false
		},

		startDim() {
			this.dim = true
		},

		stopDim() {
			this.dim = false
		},

		// Clear artwork & artist data
		clearStore() {
			this.entities = []
			this.count = 0
			this.loading = true
			this.dim = false
		},
	},
})
