<template>
	<h1>SSR Content Loading</h1>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			In order for page content to be loaded server-side, the API calls need to happen server-side too. To
			accomplish this, all API calls are done from within a route-specific store module that's dynamically
			loaded. Then the data is stored in the store's state object, which is then accessed via a computed
			property.
		</p>
		<p>
			Per example, below a list of artist names loaded by this procedure. If you load the page source, you can
			see the names are visible.
		</p>
		<ul v-if="entities.length">
			<li v-for="(entity, i) in entities" :key="i">{{ entity.name }}</li>
		</ul>
		<template v-else>Loading artists...</template>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul>
			<li>Copy-paste functions below and edit.</li>
		</ul>

		<pre v-highlightjs><code class="language-javascript">computed: {
	entities() {
		return this.indexStore.entities
	},
},

// Server-side only: load content
serverPrefetch() {
	return this.loadPage()
},

// Client-side only: Load content
mounted() {
	// Only fetch item if it wasn't already fetched on the server.
	// On production, the content is rendered by the server, but
	// the live server for development needs to load it on the client side.
	if (!this.entities.length) this.loadPage()
},

// Clear store data when we leave the page
beforeRouteLeave() {
	this.indexStore.clearStore()
},
</code></pre>
	</div>
</template>

<script>
// Stores
import { useIndexStore } from '@/stores/IndexStore'
import { useApiStore } from '@/stores/ApiStore'

// Internal
import { injectMetaData } from '@/use/MetaData'
import { prettyNr } from '@/use/StringMagic'

export default {
	name: 'DocMechanicsSSRContentLoading',
	setup() {
		const apiStore = useApiStore()
		const indexApi = apiStore.loadApi('index')
		const indexStore = useIndexStore()
		injectMetaData({
			title: 'Doc: SSR Content Loading',
		})
		return { indexApi, indexStore }
	},
	computed: {
		entities() {
			const entities = this.indexStore.entities
			return entities.slice(100, 105)
		},
	},

	// Server-side only: load Content
	serverPrefetch() {
		return this.loadPage()
	},

	// Client-side only: Load content
	mounted() {
		// Only fetch item if it wasn't already fetched on the server.
		// On production, the content is rendered by the server, but
		// the live server for development needs to load it on the client side.
		if (!this.entities.length) this.loadPage()
	},

	// Clear store data when we leave the page
	beforeRouteLeave() {
		this.indexStore.clearStore()
	},

	methods: {
		// Centralized page loading function
		async loadPage() {
			const { status, data } = await this.indexApi.get('artists', 'A')
			if (status == 200) {
				await this.indexStore.storeEntities({
					entities: data.entities,
					count: prettyNr(data.count, this.$imperial),
				})
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
